import type { NavigationItem } from '@views/project/project-aside/data/navigation'
import { checkIsSublinkOpen } from '@views/project/project-aside/helpers/check-is-sublink-open'

import type { NextRouter } from 'next/router'

type Pathname = NextRouter['pathname']
type Query = NextRouter['query']

type CategoryLinkProps = Pick<NavigationItem, 'linkPathname' | 'children'>

export function checkIsLinkActive(
  pathname: Pathname,
  { children, linkPathname }: CategoryLinkProps,
) {
  const isCategoryPageOpen = pathname === linkPathname
  const isCategoryChildrenOpen = children?.some(
    ({ linkPathname: childPathname }) => childPathname === pathname,
  )

  return isCategoryPageOpen || isCategoryChildrenOpen
}

type LinkProps = Pick<NavigationItem, 'linkPathname' | 'linkQuery'>

export function checkIsSubLinkActive(
  pathname: Pathname,
  query: Query,
  { linkPathname, linkQuery }: LinkProps,
) {
  const isCategoryPageOpen = pathname === linkPathname

  const isSublinkOpen = checkIsSublinkOpen(pathname, query, linkQuery)

  if (isCategoryPageOpen && isSublinkOpen) {
    return true
  }

  // HACK: to highlight `survey` in Settings subnavigation
  if (isCategoryPageOpen && linkQuery.activeTab === 'survey') {
    return true
  }

  return false
}
